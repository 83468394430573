import { createTheme } from '@mui/material/styles';
import background from '../data/images/background.png'

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({color: { main: mainColor }});

export const gaiaUprisingTheme = createTheme({
    palette: {
      primary: {
        main: '#132210'
      },
      secondary: {
        main: '#66aa00'
      },
      text: {
        primary: '#fff',
        secondary: 'rgba(255,255,255,0.2)'
      },
      background: {
        default: '#121212',
        paper: '#181f27'
      },
        action: {
            disabledBackground: 'rgba(255, 255, 255, 0.2)'
        },
        button: createColor('#525D7A'),
        link: createColor('#525D7A')
    },
    typography: {
        fontFamily: "Verdana, Arial, sans-serif",
        body1: {
            fontSize: "0.875rem"
        }
    },
    pageBackgroundStyle: {      
      background: `linear-gradient(to left, rgba(255, 255, 255, 0), rgba(18, 18, 18, 1) 30%), url(${background})`,
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
      backgroundPositionX: "right",
      backgroundPositionY: "32px",
      backgroundSize: "contain"
    }
  });