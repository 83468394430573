import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { Box, Stack } from '@mui/system';
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconLinks from '../constants/IconLinks';

const Navbar = ({ isDrawerOpen, onToggleDrawer, pageTitle }) => {
    return ( 
        <AppBar position="absolute" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} >
            <Toolbar>
                <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={ onToggleDrawer }>
                    { !isDrawerOpen && <MenuIcon /> }
                    { isDrawerOpen && <ArrowBackIosIcon /> }

                </IconButton>
                <h2 className="navbar-title hidden-mobile">{ pageTitle }</h2>

                <Box sx={{flexGrow: 1}} />

                <Stack direction="row" spacing={1}>
                    {
                    IconLinks.map(value => (
                        <IconButton key={value.name} href={ value.link } target="_blank" size="large" edge="start" color="inherit" aria-label={ value.name }>
                            <FontAwesomeIcon icon={value.icon} />
                        </IconButton>
                    ))}                    
                </Stack>
            </Toolbar>
        </AppBar>
     );
}
 
export default Navbar;